// src/pages/UsersByWeek.js
import React from "react";

const UsersByWeek = ({ data }) => {
  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Users by Week</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 dark:bg-gray-900 rounded-lg shadow-md">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-sm font-bold text-white">
                Week
              </th>
              <th className="px-4 py-2 text-left text-sm font-bold text-white">
                Number of Users
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data?.usersByWeek ?? {}).map(([key, value]) => {
              if (parseInt(key) < 0) return null;
                return (
                <tr key={key} className="hover:bg-gray-700 transition">
                  <td className="px-4 py-2 text-white">{key}</td>
                  <td className="px-4 py-2 text-white">{value}</td>
                </tr>
            )})}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersByWeek;

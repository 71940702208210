// src/pages/ovulationsPage.js
import React, { useState, useEffect } from "react";
import MetricCard from "../components/MetricCard";
// import { Link } from "react-router-dom";

const Ovulations = () => {
  const [ovulations, setovulations] = useState()

  useEffect(() => {
    const fetchovulations = async () => {
      const res = await fetch('https://api.babysteps.world/internal/users/ouvlation')
      const json = await res.json()
      setovulations(json)
    }

    fetchovulations()
  }, [])

  return (
    <div>
      <h1 className="text-2xl">ovulations</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
        {ovulations?.map((d, index) =>  (
          <MetricCard
          key={index}
          title={d?.fertileWindowStart?.split('T')[0]}
          value={d?.fertileWindowEnd?.split('T')[0]}
          color="text-red-500"
          icon={<i className="fas fa-notes-medical"></i>}
          additionalData={<p>{d?.userId}</p>}
        />
        ))}
        {/* <Link to="/all-ovulations">
          
        </Link>
        <Link to="/mother-ovulations">
          <MetricCard
            title="Mother ovulations"
            value="200"
            color="text-pink-500"
            icon={<i className="fas fa-female"></i>}
          />
        </Link>
        <Link to="/father-ovulations">
          <MetricCard
            title="Father ovulations"
            value="150"
            color="text-blue-500"
            icon={<i className="fas fa-male"></i>}
          />
        </Link>
        <Link to="/child-ovulations">
          <MetricCard
            title="Child ovulations"
            value="150"
            color="text-yellow-500"
            icon={<i className="fas fa-baby"></i>}
          />
        </Link> */}
      </div>
    </div>
  );
};

export default Ovulations;

// src/pages/specialistsPage.js
import React, { useState, useEffect } from "react";
import MetricCard from "../components/MetricCard";
// import { Link } from "react-router-dom";

const SpecialistsPage = () => {
  const [specialists, setspecialists] = useState()

  useEffect(() => {
    const fetchspecialists = async () => {
      const res = await fetch('https://api.babysteps.world/internal/users/specialists')
      const json = await res.json()
      setspecialists(json)
    }

    fetchspecialists()
  }, [])

  return (
    <div>
      <h1 className="text-2xl">Added Specialists</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
        {specialists?.map(sp => (
          <p key={sp._id}>{sp?.specialists?.name}</p>
        ))}
        {/* <Link to="/all-specialists">
          
        </Link>
        <Link to="/mother-specialists">
          <MetricCard
            title="Mother specialists"
            value="200"
            color="text-pink-500"
            icon={<i className="fas fa-female"></i>}
          />
        </Link>
        <Link to="/father-specialists">
          <MetricCard
            title="Father specialists"
            value="150"
            color="text-blue-500"
            icon={<i className="fas fa-male"></i>}
          />
        </Link>
        <Link to="/child-specialists">
          <MetricCard
            title="Child specialists"
            value="150"
            color="text-yellow-500"
            icon={<i className="fas fa-baby"></i>}
          />
        </Link> */}
      </div>
    </div>
  );
};

export default SpecialistsPage;

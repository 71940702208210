// src/pages/SymptomsPage.js
import React, { useState, useEffect } from "react";
import MetricCard from "../components/MetricCard";
import { Link } from "react-router-dom";
import { useWeeks } from "../components/WeekProvider";
// import { Link } from "react-router-dom";

const SymptomsPage = () => {
  const [symptoms, setSymptoms] = useState()
  const weeks = useWeeks()

  return (
    <div>
      <h1 className="text-2xl">Symptoms</h1>
      <div className="flex flex-wrap gap-2 mt-6">
        {weeks.map(week => (
          <Link to={`/symptoms/week-${week.title}?weekId=${week.id}&week=${week.title}`}>
            <span className="rounded-full p-8 flex-shrink-0 text-white font-bold flex items-center justify-center text-xl bg-gray-500">{week.title}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SymptomsPage;

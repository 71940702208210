// src/pages/UsersByStage.js
import React from "react";

const UsersByStage = ({ data }) => {
  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Users by Stage</h2>
      <div className="space-y-6">
        {Object.entries(data?.usersByStage ?? {}).map(([key, value]) => (
          <div
            key={key}
            className="bg-gray-800 dark:bg-gray-900 p-6 rounded-lg shadow-md"
          >
            <h3 className="text-xl font-bold mb-2 text-white">
              {key}
            </h3>
            <p className="text-white">Number of Users: {value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsersByStage;

// src/pages/mentalHealthPage.js
import React, { useState, useEffect } from "react";
import MetricCard from "../components/MetricCard";
// import { Link } from "react-router-dom";

const MentalHealthPage = () => {
  const [mentalHealth, setmentalHealth] = useState()

  useEffect(() => {
    const fetchmentalHealth = async () => {
      const res = await fetch('https://api.babysteps.world/internal/users/mental-health')
      const json = await res.json()
      setmentalHealth(json)
    }

    fetchmentalHealth()
  }, [])

  return (
    <div>
      <h1 className="text-2xl">Mental Health</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
        {mentalHealth?.map(m => (
          <div key={m._id}>
            <p>{m.emotion}</p>
            <p>{m.description}</p>
          </div>
        ))}
        {/* <Link to="/all-mentalHealth">
          
        </Link>
        <Link to="/mother-mentalHealth">
          <MetricCard
            title="Mother mentalHealth"
            value="200"
            color="text-pink-500"
            icon={<i className="fas fa-female"></i>}
          />
        </Link>
        <Link to="/father-mentalHealth">
          <MetricCard
            title="Father mentalHealth"
            value="150"
            color="text-blue-500"
            icon={<i className="fas fa-male"></i>}
          />
        </Link>
        <Link to="/child-mentalHealth">
          <MetricCard
            title="Child mentalHealth"
            value="150"
            color="text-yellow-500"
            icon={<i className="fas fa-baby"></i>}
          />
        </Link> */}
      </div>
    </div>
  );
};

export default MentalHealthPage;

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export const SingleContentPage = () => {
    const [checklists, setChecklists] = useState([])

    const location = useLocation();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const weekId = queryParams.get('weekId');
    const week = queryParams.get('week');

    const fetchChecklists = async () => {
      const res = await fetch(`https://api.babysteps.world/internal/content?week=${week}&weekId=${weekId}`)
      const json = await res.json()

    //   setChecklists(json)
      console.log(json)
    }

    fetchChecklists()
  }, [])

  return (
    <div>
        {/* <div class="mb-4 pb-2 border-b">
            <h2 className='text-xl font-bold mb-2'>Completed Checklists</h2>
            {checklists.filter(checklist => checklist.completed > 0).map(checklist => (
                <div key={checklist.checklist} className='flex justify-between'>
                    <p>{checklist.checklist}</p>
                    <span>{checklist.users.length}</span>
                </div>
            ))}
        </div>
        <div className='mb-4 pb-2 border-b'>
            <h2 className='text-xl font-bold mb-2'>Assigned Checklists</h2>
            {checklists.filter(checklist => checklist.assigned > 0).map(checklist => (
                <div key={checklist.checklist} className='flex justify-between'>
                    <p>{checklist.checklist}</p>
                    <span>{checklist.users.length}</span>
                </div>
            ))}
        </div>
        <div className='mb-4 pb-2 border-b'>
            <h2 className='text-xl font-bold mb-2'>Date Added Checklists</h2>
            {checklists.filter(checklist => checklist.dateAdded > 0).map(checklist => (
                <div key={checklist.checklist} className='flex justify-between'>
                    <p>{checklist.checklist}</p>
                    <span>{checklist.users.length}</span>
                </div>
            ))}
        </div>
        <div className='mb-4 pb-2 border-b'>
            <h2 className='text-xl font-bold mb-2'>Added Checklists</h2>
            {checklists.filter(checklist => checklist.addeded > 0).map(checklist => (
                <div key={checklist.checklist} className='flex justify-between'>
                    <p>{checklist.checklist}</p>
                    <span>{checklist.users.length}</span>
                </div>
            ))}
        </div>
        <div className='mb-4 pb-2 border-b'>
            <h2 className='text-xl font-bold mb-2'>Rest Checklists</h2>
            {checklists.filter(checklist => checklist.addeded === 0 && checklist.completed === 0 && checklist.assigned === 0 && checklist.dateAdded === 0 ).map(checklist => (
                <div key={checklist.checklist} className='flex justify-between'>
                    <p>{checklist.checklist}</p>
                    <span>{checklist.users.length}</span>
                </div>
            ))}
        </div>
    <div className='mb-4 pb-2 border-b'>
        <h2 className='text-xl font-bold mb-2'>Checklist Details</h2>
        <table className='w-full'>
            <thead>
                <tr>
                    <th className='text-left'>Checklist</th>
                    <th className='text-right'>Completed</th>
                    <th className='text-right'>Added</th>
                    <th className='text-right'>Assigned</th>
                    <th className='text-right'>DateAdded</th>
                </tr>
            </thead>
            <tbody>
                {checklists.map((checklist, index) => (
                    <tr key={checklist.checklist} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                        <td className='text-left'>{checklist.checklist}</td>
                        <td className='text-right'>{checklist.completed}</td>
                        <td className='text-right'>{checklist.addeded}</td>
                        <td className='text-right'>{checklist.assigned}</td>
                        <td className='text-right'>{checklist.dateAdded}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div> */}
    </div>
  )
}

// src/pages/ChecklistsPage.js
import React, { useState, useEffect } from "react";
import { useWeeks } from "../components/WeekProvider";
import { Link } from "react-router-dom";

const ChecklistsPage = () => {
  const weeks = useWeeks()

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Checklists Added/Completed</h2>
      <div className="flex flex-wrap gap-2 mt-6">
        {weeks.map(week => (
          <Link to={`/checklists/week-${week.title}?weekId=${week.id}&week=${week.title}`}>
            <span className="rounded-full p-8 flex-shrink-0 text-white font-bold flex items-center justify-center text-xl bg-gray-500">{week.title}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ChecklistsPage;

// src/pages/productsPage.js
import React, { useState, useEffect } from "react";
import MetricCard from "../components/MetricCard";
// import { Link } from "react-router-dom";

const RecommendedProducts = () => {
  const [products, setproducts] = useState()

  useEffect(() => {
    const fetchproducts = async () => {
      const res = await fetch('https://api.babysteps.world/api/recommended-products')
      const json = await res.json()
      setproducts(json)
    }

    fetchproducts()
  }, [])

  return (
    <div>
      <h1 className="text-2xl">Recommended Products</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Product Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Brand
              </th>
            </tr>
          </thead>
          <tbody>
            {products?.docs?.map((d, index) => (
              <tr key={index}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-nowrap">{d?.name}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-nowrap">{d?.brand}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecommendedProducts;

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export const SingleSymptomPage = () => {
    const [symptoms, setSymptoms] = useState([])

    const location = useLocation();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const weekId = queryParams.get('weekId');
    const week = queryParams.get('week');

    const fetchSymptoms = async () => {
      const res = await fetch(`https://api.babysteps.world/internal/symptoms?week=${week}&weekId=${weekId}`)
      const json = await res.json()

      setSymptoms(json)
    }

    fetchSymptoms()
  }, [])

  return (
    <div>
        <div class="mb-4 pb-2 border-b">
            <h2 className='text-xl font-bold mb-2'>Selected Symtpoms</h2>
            {symptoms.filter(symp => symp.selected === true).map(symp => (
                <div key={symp.symptom} className='flex justify-between'>
                    <p>{symp.symptom}</p>
                    <span>{symp.users.length}</span>
                </div>
            ))}
        </div>
        <div className='mb-4 pb-2 border-b'>
            <h2 className='text-xl font-bold mb-2'>Added Symtpoms</h2>
            {symptoms.filter(symp => symp.addeded === true).map(symp => (
                <div key={symp.symptom} className='flex justify-between'>
                    <p>{symp.symptom}</p>
                    <span>{symp.users.length}</span>
                </div>
            ))}
        </div>
        <div className='mb-4 pb-2 border-b'>
            <h2 className='text-xl font-bold mb-2'>Rest Symtpoms</h2>
            {symptoms.filter(symp => symp.selected === false && symp.addeded === false).map(symp => (
                <div key={symp.symptom} className='flex justify-between'>
                    <p>{symp.symptom}</p>
                    <span>{symp.users.length}</span>
                </div>
            ))}
        </div>
    </div>
  )
}

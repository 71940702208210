// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Home from "./components/Home";
import TotalUsersPage from "./pages/TotalUsersPage";
import SymptomsPage from "./pages/SymptomsPage";
import SpecialistsPage from "./pages/Specialists";
import ContentPage from "./pages/Content";
import MentalHealthPage from "./pages/MentalHealth";
import AllSymptoms from "./pages/AllSymptoms";
import MotherSymptoms from "./pages/MotherSymptoms";
import FatherSymptoms from "./pages/FatherSymptoms";
import ChildSymptoms from "./pages/ChildSymptoms";

import UsersByStage from "./pages/UsersByStage"; // Import the new page
import UsersByWeek from "./pages/UsersByWeek"; // Import the new page
import UsersByLocation from "./pages/UsersByLocation"; // Import the new page
import ChecklistsPage from "./pages/ChecklistsPage";
import MiscellaneousPage from "./pages/MiscellaneousPage";
import UsersPage from "./pages/Users";
import RecommendedProducts from "./pages/RecommendedProducts";
import RecommendedSpecialists from "./pages/RecommendedSpecialists";
import Feedback from "./pages/Feedback";
import Ovulations from "./pages/Ovulation";
import { SingleSymptomPage } from "./pages/SingleSymptomPage";
import { WeekProvider } from "./components/WeekProvider";
import { SingleChecklistPage } from "./pages/SingleChecklistPage";
import { SingleContentPage } from "./pages/SingleContentPage";
import { Dashboard } from "./pages/Dashboard";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch('https://api.babysteps.world/internal/users/status')
      const json = await res.json()

      setData(json)
    }

    fetchData()
  }, [])
  
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <Router>
      <div className="flex min-h-screen bg-gray-100 dark:bg-dark-bg">
        <Sidebar />
        <div className="flex flex-col flex-grow ml-64 bg-gray-100 dark:bg-dark-bg">
          <Header />
          <div className="p-4 flex justify-end">
            <button
              className="px-2 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800"
              onClick={() => setDarkMode(!darkMode)}
            >
              Toggle Dark Mode
            </button>
          </div>
          <WeekProvider>
            <main className="flex-grow p-6">
              <Routes>
                <Route path="/" element={<Home data={data} />} />
                <Route path="/dash" element={<Dashboard />} />
                <Route path="/symptoms" element={<SymptomsPage />} />
                <Route path="/symptoms/:id" element={<SingleSymptomPage />} />
                <Route path="/specialists" element={<SpecialistsPage />} />
                <Route path="/content" element={<ContentPage />} />
                <Route path="/content/:id" element={<SingleContentPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/ovulation" element={<Ovulations />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/recommended-products" element={<RecommendedProducts />} />
                <Route path="/recommended-specialists" element={<RecommendedSpecialists />} />
                <Route path="/mental-health" element={<MentalHealthPage />} />
                <Route path="/symptoms" element={<SymptomsPage />} />
                <Route path="/symptoms" element={<SymptomsPage />} />
                <Route path="/total-users" element={<TotalUsersPage />} />
                <Route path="/all-symptoms" element={<AllSymptoms />} />
                <Route path="/mother-symptoms" element={<MotherSymptoms />} />
                <Route path="/father-symptoms" element={<FatherSymptoms />} />
                <Route path="/child-symptoms" element={<ChildSymptoms />} />
                <Route path="/users-by-stage" element={<UsersByStage data={data} />} />{" "}
                {/* Add route for UsersByStage */}
                <Route path="/users-by-week" element={<UsersByWeek data={data} />} />{" "}
                {/* Add route for UsersByWeek */}
                <Route
                  path="/users-by-location"
                  element={<UsersByLocation />}
                />{" "}
                <Route path="/checklists" element={<ChecklistsPage />} />
                <Route path="/checklists/:id" element={<SingleChecklistPage />} />
                <Route path="/miscellaneous" element={<MiscellaneousPage />} />
              </Routes>
            </main>
          </WeekProvider>
        </div>
      </div>
    </Router>
  );
};

export default App;

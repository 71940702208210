// src/pages/usersPage.js
import React, { useState, useEffect } from "react";

const UsersPage = () => {
  const [users, setusers] = useState()
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const fetchusers = async () => {
      const res = await fetch('https://api.babysteps.world/internal/users')
      const json = await res.json()
      setusers(json)
    }

    fetchusers()
  }, [])

  const handleDeleteUser = async (id) => {
    const res = window.confirm("Are you sure you want to delete this user?");
    if (res) {
      const res = await fetch(`https://api.babysteps.world/internal/users/${id}`, {
        method: "DELETE"
      })

      if (res.ok) {
        alert("User Deleted Successfully")
      }
    }
  }

  return (
    <div>
      <h1 className="text-2xl"><span className={`${currentUser ? 'hover:underline cursor-pointer' : ''}`} onClick={() => setCurrentUser(null)}>Users</span> {currentUser && `> ${currentUser.name}`}</h1>
      {currentUser ? (
        <div className="mt-6">
        <div className="mb-4">
          <h3 className="text-lg font-semibold">User Information</h3>
          <div className="mt-2">
            <p><strong>Name:</strong> {currentUser.name}</p>
            <p><strong>Email:</strong> {currentUser.email}</p>
            <p><strong>Created At:</strong> {currentUser.createdAt.split('T')[0]}</p>
          </div>
        </div>
        {currentUser?.payment && (<div>
          <h3 className="text-lg font-semibold">Payment Information</h3>
          <div className="mt-2">
            <p><strong>Subscription Name:</strong> {currentUser?.payment?.notes?.subscriptionName}</p>
            <p><strong>Subscribed At:</strong> {currentUser?.payment?.subscription_created_at && new Date(currentUser?.payment?.subscription_created_at * 1000).toISOString().split('T')[0]}</p>
            <p><strong>Payment Status:</strong> {currentUser?.payment?.payment_status}</p>
            <p><strong>Next Payment Date:</strong>{currentUser?.payment?.subscription_charge_at && new Date(currentUser?.payment?.subscription_charge_at * 1000).toISOString().split('T')[0]}</p>
          </div>
        </div>)}
        {currentUser?.partnerId && (<div>
          <h3 className="text-lg font-semibold">Payment Information (Partner)</h3>
          <div className="mt-2">
            {users?.find(user => user._id === currentUser.partnerId)?.payment && (
              <>
                <p><strong>Subscription Name:</strong> {users.find(user => user._id === currentUser.partnerId)?.payment?.notes?.subscriptionName}</p>
                <p><strong>Subscribed At:</strong> {users.find(user => user._id === currentUser.partnerId)?.payment?.subscription_created_at && new Date(users.find(user => user._id === currentUser.partnerId)?.payment?.subscription_created_at * 1000).toISOString().split('T')[0]}</p>
                <p><strong>Payment Status:</strong> {users.find(user => user._id === currentUser.partnerId)?.payment?.payment_status}</p>
                <p><strong>Next Payment Date:</strong>{users.find(user => user._id === currentUser.partnerId)?.payment?.subscription_charge_at && new Date(users.find(user => user._id === currentUser.partnerId)?.payment?.subscription_charge_at * 1000).toISOString().split('T')[0]}</p>
              </>
            )}
          </div>
        </div>)}
        </div>
      ) : (<div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-left">Name</th>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-left">Email</th>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-right">Created At</th>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-right">Payment</th>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(user => (
              <tr key={user._id}>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-left hover:underline cursor-pointer"
                  onClick={() => setCurrentUser(user)}>{user.name}</td>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-left">{user.email}</td>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-right">{user?.createdAt?.split('T')[0] ?? '-'}</td>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-left">
                  {user?.partnerId && `Partner (${user?.partnerUser?.email})`} {user?.payment?.notes?.subscriptionName ?? (user.subscriptionStatus && "FREE")}
                </td>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-right">
                  <button 
                    className="px-2 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-600"
                    onClick={() => {
                      handleDeleteUser(user?._id)
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>)}
    </div>
  );
};

export default UsersPage;

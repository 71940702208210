
import * as React from "react"
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./ui/chart"

const chartConfig = {
  views: {
    label: "Users",
  },
  desktop: {
    label: "Users",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Users",
    color: "hsl(var(--chart-2))",
  },
}

export function TotalNewUsersChart() {
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState('weekly');
  const [loading, setLoading] = React.useState(true);
  const [activeChart, setActiveChart] = React.useState('desktop')

  React.useEffect(() => {
    const fetchActiveUsers = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://api.babysteps.world/internal/stats/new-users-without-sub?period=${filter}`);
        const data = await response.json();
        const graphData = data.newUsers.map(d => {
            return { date: d.date, desktop: d.count, mobile: d.count };
        });
        setData(graphData);
      } catch (error) {
        console.error('Error fetching active users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveUsers();
  }, [filter]);

  const total = React.useMemo(
    () => ({
      total: data.reduce((acc, curr) => acc + curr.desktop, 0),
      average: (data.length ? (data.reduce((acc, curr) => acc + curr.desktop, 0)) / data.length : 0),
    }),
    [data]
  )

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle>New Signups</CardTitle>
          <CardDescription>
            Showing Total New User Acquisitions for {filter}
          </CardDescription>
          <CardFooter>
            <select onChange={(e) => setFilter(e.target.value)} value={filter} className="form-select block w-auto mt-1" defaultValue="7d">
                <option value="7d">7 Days</option>
                <option value="1m">1 Month</option>
                <option value="3m">3 Months</option>
                <option value="1y">1 Year</option>
                <option value="lifetime">Life Time</option>
            </select>
          </CardFooter>
        </div>
        <div className="flex">
          {["total", "average"].map((key) => {
            const chart = key
            return (
              <button
                key={chart}
                data-active={true}
                className="flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                // onClick={() => setActiveChart(chart)}
              >
                <span className="text-xs capitalize text-muted-foreground">
                  {chart}
                </span>
                <span className="text-lg font-bold leading-none sm:text-3xl">
                  {total[key].toFixed(2)}
                </span>
              </button>
            )
          })}
        </div>
      </CardHeader>
      <CardContent className="px-2 sm:p-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <LineChart
            accessibilityLayer
            data={data}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => {
                const date = new Date(value)
                return date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })
              }}
            />
            <YAxis
              tickFormatter={(value) => value}
              label={{
                position: 'outsideLeft',
                angle: -90,
                fill: 'var(--color-gray-700)',
                fontSize: 12,
                fontWeight: 500,
                textAnchor: 'middle',
                value: chartConfig.views.label
              }}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  className="w-[150px]"
                  nameKey="views"
                  labelFormatter={(value) => {
                    return new Date(value).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })
                  }}
                />
              }
            />
            <Line
              dataKey={activeChart}
              type="monotone"
              stroke={`var(--color-${activeChart})`}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

// src/pages/specialistsPage.js
import React, { useState, useEffect } from "react";
import MetricCard from "../components/MetricCard";
// import { Link } from "react-router-dom";

const RecommendedSpecialists = () => {
  const [specialists, setspecialists] = useState()

  useEffect(() => {
    const fetchspecialists = async () => {
      const res = await fetch('https://api.babysteps.world/api/recommended-specialists')
      const json = await res.json()
      setspecialists(json)
    }

    fetchspecialists()
  }, [])

  return (
    <div>
      <h1 className="text-2xl">Recommended specialists</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-left">Name</th>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-left">Expertise</th>
              <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-gray-700 text-left">Contact</th>
            </tr>
          </thead>
          <tbody>
            {specialists?.docs?.map((d, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-left">{d?.name}</td>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-left">{d?.expertise}</td>
                <td className="py-2 px-4 border-b border-gray-300 dark:border-gray-700 text-left">{d?.contact}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecommendedSpecialists;

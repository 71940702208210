import { ActiveUsersChart } from "../components/ActiveUsers"
import { AverageActiveUsersChart } from "../components/AverageActiveUsers"
import { NewUsersChart } from "../components/NewUsersChart"
import { ScreenTimeChart } from '../components/ScreenTime'
import { ShadcnChart } from "../components/shadcn-chart"
import { TotalNewUsersChart } from "../components/TotalNewUsersChart"

export const Dashboard = () => {
  return (
    <div>
       <h1 className="text-2xl mb-2">Dashboard</h1>
        <div class="space-y-4">
          <ActiveUsersChart />
          <AverageActiveUsersChart />
          <NewUsersChart />
          <ScreenTimeChart />
          <TotalNewUsersChart />
        </div>
    </div>
  )
}

import * as React from "react"
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./ui/chart"

const chartConfig = {
  views: {
    label: "Seconds",
  },
  desktop: {
    label: "Seconds",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Seconds",
    color: "hsl(var(--chart-2))",
  },
}

export function ScreenTimeChart() {
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState('weekly');
  const [loading, setLoading] = React.useState(true);
  const [activeChart, setActiveChart] = React.useState('desktop')

  React.useEffect(() => {
    const fetchActiveUsers = async () => {
      setLoading(true);
      const currentDate = new Date();
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);

      const currentDateString = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1).toString().padStart(2, '0') + '-' + currentDate.getDate().toString().padStart(2, '0');
      const sevenDaysAgoString = sevenDaysAgo.getFullYear() + '-' + (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0') + '-' + sevenDaysAgo.getDate().toString().padStart(2, '0');
      try {
        let allData = [];
        let page = 1;
        let totalPages = 1;
        while (page <= totalPages) {
          const response = await fetch(`https://api.openpanel.dev/export/events?babysteps=abc&event=screen_view&start=${sevenDaysAgoString}&end=${currentDateString}&page=${page}`, {
            headers: {
              'openpanel-client-id': '8188221a-e6ad-495c-9302-d86f7a4755ef',
              'openpanel-client-secret': 'sec_6f98dbf28e42bc0167c1'
            }
          });
          const data = await response.json();
          if (page === 1) {
            totalPages = data.meta.pages;
          }
          allData.push(...data.data) 
          page++;
        }
        // console.log(allData)
        const graphData = allData.filter(d => d.path.includes('companion') && !d.path.includes('demo')).reduce((acc, d) => {
          const date = d.createdAt;
          const desktopDuration = d.duration / 1000;
          const mobileDuration = d.duration / 1000;
          const existing = acc.find(item => item.date === date);
          if (existing) {
            existing.desktop += desktopDuration;
            existing.mobile += mobileDuration;
          } else {
            acc.push({ date, desktop: desktopDuration, mobile: mobileDuration });
          }
          return acc;
        }, []);
        setData(graphData);
      } catch (error) {
        console.error('Error fetching active users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveUsers();
  }, [filter]);

  const total = React.useMemo(
    () => ({
      total: data.reduce((acc, curr) => acc + curr.desktop, 0),
      average: (data.length ? (data.reduce((acc, curr) => acc + curr.desktop, 0)) / data.length : 0),
    }),
    [data]
  )

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle>Time Spent</CardTitle>
          <CardDescription>
            Showing Time Spent for {filter}
          </CardDescription>
          <CardFooter>
            <select onChange={(e) => setFilter(e.target.value)} value={filter} className="form-select block w-auto mt-1" defaultValue="7d">
                <option value="7d">7 Days</option>
                <option value="1m">1 Month</option>
                <option value="3m">3 Months</option>
                <option value="1y">1 Year</option>
                <option value="lifetime">Life Time</option>
            </select>
          </CardFooter>
        </div>
        <div className="flex">
          {["total", "average"].map((key) => {
            const chart = key
            return (
              <button
                key={chart}
                data-active={true}
                className="flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                // onClick={() => setActiveChart(chart)}
              >
                <span className="text-xs capitalize text-muted-foreground">
                  {chart}
                </span>
                <span className="text-lg font-bold leading-none sm:text-3xl">
                  {total[key].toFixed(2)}
                </span>
              </button>
            )
          })}
        </div>
      </CardHeader>
      <CardContent className="px-2 sm:p-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <LineChart
            accessibilityLayer
            data={data}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => {
                const date = new Date(value)
                return date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })
              }}
            />
            <YAxis
              tickFormatter={(value) => value}
              label={{
                position: 'outsideLeft',
                angle: -90,
                fill: 'var(--color-gray-700)',
                fontSize: 12,
                fontWeight: 500,
                textAnchor: 'middle',
                value: chartConfig.views.label
              }}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  className="w-[150px]"
                  nameKey="views"
                  labelFormatter={(value) => {
                    return new Date(value).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })
                  }}
                />
              }
            />
            <Line
              dataKey={activeChart}
              type="monotone"
              stroke={`var(--color-${activeChart})`}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

import React from "react";
import MetricCard from "./MetricCard";

const Home = ({ data }) => {
  return (
  <div>
    {/* <div className="flex gap-6 w-full">
      <ActiveUsersChart />
      <ScreenTimeChart /> */}
    {/* </div> */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <MetricCard
        title="Total Users"
        value={data?.totalUsers}
        color="text-blue-500" // Adjust the color of the icon
        icon={<i className="fas fa-users"></i>}
        link="/total-users"
      />
      <MetricCard
        title="Mother Accounts"
        value={data?.userByRole?.nurturer}
        color="text-pink-500"
        icon={<i className="fas fa-female"></i>}
      />
      <MetricCard
        title="Father Accounts"
        value={data?.userByRole?.caregiver}
        color="text-blue-500"
        icon={<i className="fas fa-male"></i>}
      />
      <MetricCard
        title="Connected Accounts"
        value={data?.partnerAccounts}
        color="text-green-500"
        icon={<i className="fas fa-link"></i>}
      />
      <MetricCard
        title="Single Accounts"
        value={data?.totalUsers - data?.partnerAccounts}
        color="text-yellow-500"
        icon={<i className="fas fa-user"></i>}
      />
    </div>
  </div>
)};

export default Home;

import React, { createContext, useContext, useEffect, useState } from 'react';

const WeekContext = createContext();

export const useWeeks = () => useContext(WeekContext);

export const WeekProvider = ({ children }) => {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    const fetchWeeks = async () => {
      const res = await fetch('https://api.babysteps.world/api/weeks?limit=44');
      const json = await res.json();

      const filteredWeeks = json?.docs?.filter(week => parseInt(week.title) > 0).sort((a, b) => parseInt(a.title) - parseInt(b.title));
      setWeeks(filteredWeeks);
    };

    fetchWeeks();
  }, []);

  return (
    <WeekContext.Provider value={weeks}>
      {children}
    </WeekContext.Provider>
  );
};
